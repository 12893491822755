<template>
  <div class="review-card">
    <div class="review-card__head">
      <span>{{ dateString }}</span>
      <Component
        :is="(isManager && item.source_url) ? NuxtLink : 'div'"
        :to="item?.source_url || ''"
        rel="nofollow"
        class="review-link-from"
      >
        {{ item.source }}
      </Component>
    </div>
    <div class="review-card__delimiter" />
    <div class="review-card__name-rating">
      <div class="--name">{{ item.name }}</div>
      <div class="--rating">
        <template
          v-for="(r) in [1,2,3,4,5]"
          :key="`raiting-item-${r}`"
        >
          <img
            v-if="item.rating >= r"
            src="~/assets/img/svg/common/star.svg"
            alt="rating"
          />
          <img
            v-else
            src="~/assets/img/svg/common/star-empty.svg"
            alt="rating"
          />
        </template>
      </div>
    </div>


    <template v-if="mediaItems.length > 0">
      <div class="review-card__images">
        <ReviewCardSwiperMedia :media="mediaItems"/>
      </div>
    </template>

    <div
      ref="refMessage"
      class="review-card__message h5"
      :class="{
        '--small': isSmallText,
        '--full': isFullMessage
      }"
    >
      <div v-html="item.text" />
    </div>
    <div
      v-if="isSmallText"
      class="review-card__button-more h6"
      @click="changeFullMessage"
    >
      {{ isFullMessage ? 'Свернуть отзыв' : 'Отзыв целиком' }}
      <img
        src="~/assets/img/svg/common/arrow.svg"
        alt="arrow"
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components';
import type { ComputedRef } from 'vue';

const props = defineProps<{
	item: { [key: string]: any };
	isManager: boolean;
}>();
const ReviewCardSwiperMedia = defineAsyncComponent(() => import('~/components/reviews/ReviewCardSwiperMedia.vue'));

const dateString = computed(() => {
  return new Date(props.item.created_at * 1000).toLocaleDateString('ru', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
});
const mediaItems: ComputedRef<any[]> = computed(() => {
  const _files = props?.item?.files || [];
  const _images = props?.item?.images_ids || [];

  let _items: any[] = [];
  if (_images.length > 0) {
    _images.map((t: any) => {
      _items.push({
        type: 'image',
        value: t
      })
    })
  }
  if (_files.length > 0) {
    _files.map((t: any) => {
      _items.push({
        type: 'video',
        value: t?.url
      })
    })
  }

  return _items
});

const refMessage: any = ref(null);
const isSmallText = ref(false);
const isFullMessage = ref(false);
const checkFullMessage = () => {
  const fullHeight = refMessage.value?.clientHeight;
  if (fullHeight <= (8 * 22)) {
    return;
  }

  isSmallText.value = true;
};
const changeFullMessage = () => {
  isFullMessage.value = !isFullMessage.value;
};
onMounted(() => {
  checkFullMessage();
});
</script>

<style lang="scss">
.review-card {
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	background: #F4F3F1;
	padding: 32px 24px;
	padding-bottom: 24px;
	box-sizing: border-box;
}

.review-link-from {
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	color: var(--silver);
}

.review-card__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #989898;
	font-size: 12px;
	font-weight: 500;
}

.review-card__delimiter {
	width: 100%;
	height: 1px;
	margin-top: 16px;
	margin-bottom: 48px;
	background-color: #EBEBEB;
}

.review-card__name-rating {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 32px;

	.--name {
		color: #000;
		font-variant-numeric: lining-nums proportional-nums;
		font-size: 24px;
		font-weight: 500;
		line-height: 100%;
	}

	.--rating {
		display: flex;
		align-items: center;
	}
}

.review-card__images {
	margin-bottom: 32px;
}

.review-card__message {
	margin-bottom: 18px;
	color: #000;
	line-height: 22px;

	&.--small {
		-webkit-line-clamp: 8;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		font-variant-numeric: lining-nums proportional-nums;
		text-overflow: ellipsis;
	}

	&.--full {
		-webkit-line-clamp: 999999;
	}

	&.--full + .review-card__button-more img {
		transform: rotate(90deg);
	}
}

.review-card__button-more {
	margin-top: auto;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #000;
	opacity: 0.5;
	transition: all 0.2s;

	img {
		width: 16px;
		height: 16px;
		object-fit: contain;
		transform: rotate(270deg);
	}

	&:hover {
		opacity: 1;
	}
}

@media (max-width: 1359px) {
  .review-card__name-rating .--name {
    flex: 1;
  }
}
@media (max-width: 1199px) {
  .review-card__delimiter {
    margin-bottom: 32px;
  }
  .review-card__name-rating .--name {
    font-size: 18px;
  }
  .review-card__name-rating .--rating {
    & > * {
      width: 24px;
      height: 24px;
    }
  }
}
@media (max-width: 1023px) {
	.review-card {
		padding: 28px 24px;
	}
	.review-card__delimiter {
		margin-top: 10px;
		margin-bottom: 24px;
	}
	.review-card__name-rating {
		margin-bottom: 24px;

		.--name {
			font-size: 18px;
		}

		.--rating {
			img {
				width: 23px;
			}
		}
	}
	.review-card__images {
		margin-bottom: 24px;
	}
	.review-card__message {
		font-size: 14px !important;
		line-height: 150% !important;
		font-weight: 400;
	}
}
</style>
